import "../modules/_datetime-to-local.ts";


let periodSelect = <HTMLSelectElement> document.getElementById("period");
let metricSelect = <HTMLSelectElement> document.getElementById("metric");

function changePath() {
	window.location.href = this.value;
};

periodSelect.addEventListener('change', changePath);
metricSelect.addEventListener('change', changePath);