let datetimeElement = <HTMLSpanElement> document.getElementById("datetime-to-local");
let datetimeUTC: string | null = null;
if (datetimeElement) {
    datetimeUTC = datetimeElement.textContent;
};
if (datetimeUTC) {
    let datetimeUTCObject: Date = new Date(datetimeUTC);
    let options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    let datetimeLocal: string = datetimeUTCObject.toLocaleString('en-US', options);
    datetimeElement.textContent = datetimeLocal;
    datetimeElement.style.visibility = "visible";
};